import React from "react"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import HelpPage from "../components/help/HelpPage.js"
import { Container } from "react-bootstrap"
import natureBg from "../images/bgs/bg4.svg"

const Help = () => (
  <Layout image={natureBg}>
    <SEO title="Help Center" />
    <Container fluid={true}>
      <HelpPage />
    </Container>
  </Layout>
)

export default Help
