import React from "react"
import { Container, Row, Figure } from "react-bootstrap"
import TmpLogo from "../../images/logo.png"
import createAccount from "../../images/Help/accessAccount.svg"
import postProject from "../../images/Help/postProject.svg"
import signup from "../../images/Help/signup.svg"
import logHours from "../../images/Help/logHours.svg"
import contact from "../../images/Help/contact.svg"
import grantHours from "../../images/Help/grantHours.svg"
import chooseStudent from "../../images/Help/chooseStudent.svg"
import contactUs from "../../images/Help/contactUs.svg"
import walkthru from "../../images/Help/walkthru.svg"
import HelpModal from "./HelpModal.js"
import HelpPageCard from "./HelpPageCard.js"

export default class HelpPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      runStates: [false, null, null, null, null, null, null],
    }
  }

  setModalState = (index, state) => {
    let tmp = this.state.runStates
    tmp[index] = state
    this.setState({ runStates: tmp })
  }

  render() {
    let modals = []
    let modalLinks = [
      "https://docs.google.com/presentation/d/1PZhYvEDD2g0R0ACeZ8Gp_MdCqMYSiDogLqkOIe_s5W8/edit?usp=sharing",
      "https://docs.google.com/presentation/d/1V3uelhckNPAbovErEl4sv2zVbnJpc5b7pH-T7GFtY5M/edit?usp=sharing",
    ]

    for (let i = 0; i < this.state.runStates.length; i++) {
      modals.push(
        <HelpModal
          run={this.state.runStates[i]}
          index={i}
          refresh={this.setModalState}
          teacherLink={modalLinks[0]}
          studentLink={modalLinks[1]}
        />
      )
    }
    let cards = []

    let titles = [
      "Creating An Account",
      "Posting a Project",
      "Signing up for a Project",
      "Choose A Student",
      "Contact Student/Teacher",
      "Logging Hours",
      "Grant Volunteer Hours",
    ]
    let images = [
      createAccount,
      postProject,
      signup,
      chooseStudent,
      contact,
      logHours,
      grantHours,
    ]

    let links = [
      null,
      "https://docs.google.com/presentation/d/1NeKJa_ZljrFUic_zYvwRsxVQ1grcM60mid8OA9AIQCw/edit?usp=sharing",
      "https://docs.google.com/presentation/d/139ghDdt6DeO6GEIpE1L8LGdwM1kEdiQ679u1EMOlpcc/edit?usp=sharing",
      "https://docs.google.com/presentation/d/1yx43DNOgnGgajftz6DjDbFu8E-Q-TP9oFq07mLX9NFU/edit?usp=sharing",
      "https://docs.google.com/presentation/d/1cY6uAvAcjlHnnkxEm6YuETdvBMnJ-zUwu899VfXiTw8/edit?usp=sharing",
      "https://docs.google.com/presentation/d/15X0QWYPDxAA8r0AxiyfczUJnd-sWbL-eb5VtjklOZjc/edit?usp=sharing",
      "https://docs.google.com/presentation/d/1gwcPURM1RTfJKcdBHu7ufl1uoZSa77BvfROWw56hFX8/edit?usp=sharing",
    ]

    let moreSupportCards = []
    let moreTitles = [
      "Full RemoteTA Walkthrough Video",
      "Email us! support@remoteta.org",
    ]
    let moreImages = [walkthru, contactUs]
    let moreLinks = [
      "https://drive.google.com/file/d/1HoMXNgpzO0SxNd-1Q-8e8YoxVfGVfTj-/view?usp=sharing",
      "mailto:support@remoteta.com",
    ]

    for (let i = 0; i < titles.length; i++) {
      if (this.state.runStates[i] !== null) {
        cards.push(
          <HelpPageCard
            title={titles[i]}
            image={images[i]}
            callback={() => this.setModalState(i, true)}
          />
        )
      } else {
        cards.push(
          <HelpPageCard
            title={titles[i]}
            image={images[i]}
            callback={null}
            link={links[i]}
          />
        )
      }
    }

    for (let i = 0; i < moreTitles.length; i++) {
      moreSupportCards.push(
        <HelpPageCard
          title={moreTitles[i]}
          image={moreImages[i]}
          link={moreLinks[i]}
        />
      )
    }

    return (
      <Container style={{ marginTop: "60px", marginBottom: "50px" }}>
        {modals}
        <Row className="justify-content-center">
          <Figure>
            <Figure.Image width={200} src={TmpLogo} />
          </Figure>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "10px" }}>
          <h1 style={{ textAlign: "center" }}>How Can We Help You?</h1>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "30px" }}>
          <h3 style={{ textAlign: "center" }}>
            <u>Walkthrough Video</u>
          </h3>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "20px" }}>
          {moreSupportCards[0]}
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "60px" }}>
          <h3 style={{ textAlign: "center" }}>
            <u>Quick Tutorials</u>
          </h3>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "20px" }}>
          {cards}
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "50px" }}>
          <h3 style={{ textAlign: "center" }}>
            <u>Need Additional Support? Contact Us!</u>
          </h3>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "20px" }}>
          {moreSupportCards[1]}
        </Row>
      </Container>
    )
  }
}
