import React, { useState } from "react"

import { Modal, Button } from "react-bootstrap"

export default function HelpModal(props) {
  const [show, setShow] = useState(false)
  const [forced, setForced] = useState(false)

  const handleClose = () => {
    props.refresh(props.index, false)
    setShow(false)
    setForced(false)
  }
  const handleShow = () => setShow(true)

  if (!forced && props.run) {
    setForced(true)
    handleShow()
  }

  const handleLocation = loc => {
    window.location.href = loc
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Teacher or Student?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to view the student tutorial or the teacher tutorial?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleLocation(props.studentLink)}
          >
            Student
          </Button>
          <Button
            variant="primary"
            onClick={() => handleLocation(props.teacherLink)}
          >
            Teacher
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
