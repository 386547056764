import React from "react"
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle } from "mdbreact"
import { Col } from "react-bootstrap"

function HelpPageCard(props) {
  const callbackWrapper = () => {
    if (props.callback) {
      props.callback()
    } else {
      window.location.href = props.link
    }
  }

  return (
    <Col style={{ maxWidth: "22rem", marginTop: "20px" }} md={4}>
      <MDBCard onClick={callbackWrapper}>
        <MDBCardImage
          className="img-fluid"
          src={props.image}
          style={{ height: "15rem", padding: "10px" }}
          waves
        />
        <MDBCardBody>
          <MDBCardTitle>{props.title}</MDBCardTitle>
        </MDBCardBody>
      </MDBCard>
    </Col>
  )
}

export default HelpPageCard
